<template>
  <div class="map">
    <el-input
      v-show="false"
      v-model="addressKeyword"
      placeholder="请输入地址来直接查找相关位置"
    ></el-input>
    <baidu-map
      class="bmView"
      :scroll-wheel-zoom="true"
      :center="location"
      :zoom="zoom"
      ak="npSffTzvQKCk81MMoovi6ZMP8UEaGrEG"
    >
      <bm-marker
        :position="location"
        :dragging="true"
        animation="BMAP_ANIMATION_BOUNCE"
        @click="infoWindowOpen"
      >
        <bm-label
          :content="keyword"
          :labelStyle="labelStyle"
          :offset="idbr ? offsets : offset"
        ></bm-label>
      </bm-marker>
      <bm-view :style="`width: 100%; height: ${mapheight}; flex: 1`"></bm-view>
      <!-- <bm-local-search
        :keyword="addressKeyword"
        :auto-viewport="true"
        style="display: none"
      ></bm-local-search> -->
    </baidu-map>
  </div>
</template>
<script>
import global from "@/assets/js/globalconfig";
import BaiduMap from "vue-baidu-map/components/map/Map.vue";
import BmView from "vue-baidu-map/components/map/MapView.vue";
import BmLocalSearch from "vue-baidu-map/components/search/LocalSearch.vue";
import BmMarker from "vue-baidu-map/components/overlays/Marker.vue";
import BmLabel from "vue-baidu-map/components/overlays/Label.vue";
import BmInfoWindow from "vue-baidu-map/components/overlays/InfoWindow.vue";
import { Getposigps } from "@/api/other";
export default {
  components: {
    BaiduMap,
    BmView,
    BmLocalSearch,
    BmMarker,
    BmLabel,
    BmInfoWindow,
  },
  props: {
    mapheight: {
      defaultValue: "300px",
      require: false,
    },
    keyword: {
      defaultValue: "",
      require: false,
    },
    idbr: {
      type: Boolean,
      defaultValue: false,
    },
  },
  watch: {
    keyword(val) {
      this.GetLocation(val);
    },
  },
  data() {
    return {
      location: {
        lng: 120.247921,
        lat: 29.296554,
      },
      show: true,
      zoom: 18,
      labelStyle: {
        color: "#000",
        fontSize: "12px",
        "border-color": "#000",
      },
      addressKeyword: "",
      offset: { width: -10, height: -40 },
      offsets: { width: -80, height: -60 },
    };
  },
  mounted() {
    this.GetLocation(this.keyword);
    if (this.idbr) {
      this.labelStyle.width = "200px";
      this.labelStyle["white-space"] = "normal";
    }
  },
  methods: {
    GetLocation(add) {
      var _this = this;
      var par = {
        field: add,
      };
      Getposigps(par).then((res) => {
        if (res.success) {
          _this.location = res.data;
        }
      });
    },
    infoWindowClose() {
      this.show = false;
    },
    infoWindowOpen() {
      this.show = true;
    },
  },
};
</script>
<style scoped>
.map {
  width: 100%;
}
</style>