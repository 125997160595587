import axios from "axios";
import globalconfig from "@/assets/js/globalconfig";
/****获取验证码****/
/* key: '6e7239cd1f1aaf595bca3753a67986f8',
address: this.companyInfo.registeredAddress */
export const Getposigps = params => {
	var url = globalconfig.baseurl + "/apipc/posigps";
	return axios.post(url, params);
};
export const getwxpay = params => {
	var url = globalconfig.baseurl + "/api/WxPay/QrCodePay";
	return axios.post(url, params);
};
export const getwxpaylj = params => {
	var url = globalconfig.baseurl + "/api/WxPay/QrCodePaylj";
	return axios.post(url, params);
};
export const getpayOrder = params => {
	var url = globalconfig.baseurl + "/api/WxPay/payOrder";
	return axios.post(url, params);
};
export const payOrderlj = params => {
	var url = globalconfig.baseurl + "/api/WxPay/payOrderlj";
	return axios.post(url, params);
};

